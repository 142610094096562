import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/components/Layout.vue'
const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'product/google',
        name: 'productgoogle',
        component: () => import('@/views/ProductGoogle.vue')
      },
      {
        path: 'product/facebook',
        name: 'productfacebook',
        component: () => import('@/views/ProductFacebook.vue')
      },
      {
        path: 'product/bing',
        name: 'productbing',
        component: () => import('@/views/ProductBing.vue')
      },
      {
        path: 'product/linkedin',
        name: 'productlinkedin',
        component: () => import('@/views/ProductLinkedIn.vue')
      },
      {
        path: 'product/twitter',
        name: 'producttwitter',
        component: () => import('@/views/ProductTwitter.vue')
      },{
        path: 'product/tiktok',
        name: 'producttiktok',
        component: () => import('@/views/ProductTikTok.vue')
      },
      {
        path: 'product/dtc',
        name: 'productdtc',
        component: () => import('@/views/PorductDtc.vue')
      },
      {
        path:'product/btb',
        name:'productbtb',
        component: () => import('@/views/PorductBtb.vue')

      },
      {
        path:'product/value',
        name:'productvalue',
        component: () => import('@/views/ProductValue.vue')
      },
      {
        path:'product/contact',
        name:'productcontact',
        component: () => import('@/views/ProductContact.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
