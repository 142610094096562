<template>
  <header :style="{ marginTop: isTranlating ? '38px' : '0' }" class="header">
    <div class="container">
      <RouterLink to="/home" class="logo-wrapper">
        <img src="../../public/images/logo.png" alt="" />
      </RouterLink>
      <el-menu
        router
        class="custom-el-menu"
        style="width: 600px"
        mode="horizontal"
        :router="true"
        :default-active="route.path"
      >
        <el-menu-item index="/home">首页</el-menu-item>
        <el-sub-menu>
          <template #title>海外营销平台</template>
          <el-menu-item
            v-for="item in menus"
            class="custom-sub-menu-wrapper"
            :index="item.to"
            :key="item.to"
          >
            <img :src="item.img" alt="" />
            {{ item.title }}
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3">
          <template #title>代投放服务</template>
          <el-menu-item index="/product/dtc">DTC 品牌服务</el-menu-item>
          <el-menu-item index="/product/btb">BTB 客户服务</el-menu-item>
          <el-menu-item index="/product/value">增值服务</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="/product/contact">关于我们</el-menu-item>
      </el-menu>
      <div class="telephone">WhatsApp: +85244204080</div>
    </div>
  </header>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
console.log(route);

const menus = [
  {
    to: "/product/google",
    title: "Google",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/google.png",
  },
  {
    to: "/product/facebook",
    title: "Facebook",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/facebook.png",
  },
  {
    to: "/product/bing",
    title: "Bing",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/bing.png",
  },
  {
    to: "/product/linkedin",
    title: "LinkedIn",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/linkedin.png",
  },
  {
    to: "/product/twitter",
    title: "Twitter",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/twitter.png",
  },
  {
    to: "/product/tiktok",
    title: "Tiktok",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/tiktok.png",
  },
];
const isTranlating = ref(false);
onMounted(() => {
  window.addEventListener("languageChanged", (event) => {
    isTranlating.value = event.detail;
  });
});
</script>

<style scoped lang="less">
header {
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(44, 114, 255, 0.05);
  height: 64px;
  left: 0;
  line-height: 64px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.2s ease-in-out;
  transition: top 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;
  z-index: 111;
  display: flex;
  .container {
    display: flex;
    height: 100%;
    margin: 0 auto;
    // width: 1080px;

    align-items: center;
    .logo-wrapper {
      display: inline-block;
      height: 100%;
      margin-right: 10px;
      vertical-align: middle;
      img {
        height: 100%;
      }
    }
    .custom-el-menu {
      width: 550px;
      height: 100%;
      border-bottom: 0;
      .el-menu-item {
        border-bottom: 0;
      }
    }
    .telephone {
      color: #2f6aff;
      font-size: 24px;
      line-height: 35px;
      margin-right: 20px;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
}
</style>
<style>
.custom-sub-menu-wrapper {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
</style>
