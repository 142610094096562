<template>
  <router-view />
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
.banner {
  height: 540px;
  width: 100%;
  margin-top: 64px;
  .banner-item {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    .banner-logo {
      img {
        display: inline-block;
        margin-right: 15px;
        vertical-align: sub;
        width: 40px;
      }
    }
    .title {
      color: #fff;
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 3px;
      line-height: 70px;
      margin-bottom: 4px;
    }
    .desc {
      color: #fff;
      font-size: 18px;
      line-height: 27px;
      margin: 8px 0 60px;
      opacity: 0.9;
    }
  }
}
.night-demo {
  padding: 80px 0;
  text-align: center;
  .comp-atible {
    margin: auto;
    overflow: hidden;
    width: 1080px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .three-flex-grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    padding-left: 20px;
  }
  .icon-card {
    background: #fff;
    border-radius: 4px;
    float: left;
    height: 320px;
    text-align: center;
    width: 100%;
    img {
      margin: 40px auto;
      width: 85px;
    }
    .icon-text {
      margin: 10px;
      p {
        color: #2b2b2c;
        font-size: 16px;
        margin-bottom: 30px;
      }
      .night-text {
        color: #727476;
        font-size: 14px;
        margin: 0 10px 30px;
        text-align: left;
      }
    }
  }
  .second-flex-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 60px;
    padding-left: 20px;
    padding-right: 2px;
  }
  .night-flex-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    padding-left: 20px;
  }
}
.eleven-demo {
  overflow: hidden;
  padding: 120px 0;
  text-align: center;
  .comp-atible {
    margin: auto;
    overflow: hidden;
    width: 1080px;
  }
  .eleven-list {
    display: inline-block;
    left: 0;
    padding: 0 80px;
    position: relative;
    text-align: left;
    width: 300px;
    .eleven-aboust {
      left: 0;
      position: absolute;
      top: 0;
      p {
        color: #333;
        font-size: 50px;
        margin: 0;
        font-weight: 500;
      }
      .eleven-text {
        color: #333;
        font-size: 14px;
        letter-spacing: 1px;
        margin: 60px 0;
        width: 260px;
      }
      .name-small {
        font-size: 12px;
        font-weight: 700;
        margin-top: 40px;
      }
    }
  }
  .eleven-img {
    float: right;
    margin-right: 100px;
    width: 650px;
  }
}
.twenty-one {
  position: relative;
  .twenty-content {
    color: #fff;
    display: flex;
    height: 900px;
    width: 100%;
    .twenty-text {
      display: flex;
      flex: 5;
      justify-content: flex-end;
      padding: 115px 79px 0 110px;
      width: 620px;
      .reason-content {
        padding-left: 20px;
        width: 620px;
        .twenty-first {
          font-size: 50px;
          margin: 0;
        }
        .twenty-line {
          height: 40px;
          margin-bottom: 20px;
          margin-top: 10px;
          width: 2px;
        }
        .twenty-second {
          font-size: 35px;
          font-weight: 600;
          margin: 0;
        }
        .twenty-three {
          font-size: 35px;
          font-weight: 600;
          margin: 0;
        }
        .twenty-four {
          font-size: 14px;
          line-height: 30px;
          margin: 20px 0;
        }
        .btn-exten {
          border: none;
          border-radius: 28px;
          cursor: pointer;
          display: inline-block;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 20px;
          margin-top: 20px;
          outline: none;
          padding: 10px 35px;
        }
        .fillet {
          border-radius: 28px !important;
        }
      }
    }
    .twenty-right {
      background-repeat: no-repeat;
      background-size: cover;
      flex: 4;
      height: 100%;
    }
  }
}
.nineteen-demo {
  background: rgb(255, 255, 255);
  overflow: hidden;
  padding: 60px 0;
  text-align: center;
  .comp-atible {
    margin: auto;
    overflow: hidden;
    width: 1080px;
    p {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .night-flex {
      align-items: center;
      display: flex;
      .left-content {
        display: inline-block;
        margin: 20px 0 30px;
        padding: 50px;
        position: relative;
        width: 480px;
        p {
          font-size: 24px;
          margin-left: 10px;
          margin-top: 0;
          text-align: left;
        }
        .left-list {
          display: inline-block;
          margin-bottom: 40px;
          width: 45%;
          .left-title {
            font-size: 14px;
            font-weight: 600;
            margin: 0 0 15px;
            text-align: left;
          }
          .left-text {
            font-size: 14px;
            text-align: left;
          }
        }
      }
      img {
        display: inline-block;
        margin-left: 80px;
        width: 430px;
      }
    }
  }
}
.love-fifteen {
  overflow: hidden;
  text-align: center;
  .fifteen-center {
    height: 680px;
    margin: auto;
    padding: 60px 0;
    width: 1080px;
    p {
      font-size: 26px;
      font-weight: 600;
    }
    .tab {
      font-size: 0;
      left: 0;
      list-style: none;
      margin: 0 auto;
      padding: 60px 0;
      position: relative;
      width: 1080px;
      li {
        font-size: 0;
        text-align: left;
        input {
          left: -999px;
          position: absolute;
        }
        img {
          float: right;
          width: 600px;
          transform: translateY(-80px);
        }
        label {
          cursor: pointer;
          display: block;
          font-size: 14px;
          opacity: 0.5;
          padding: 20px;
          position: relative;
          transition: all 0.2s ease-in-out;
          z-index: 11111;
          .nav-left {
            background: #e8ecf1;
            border-radius: 50%;
            display: inline-block;
            height: 10px;
            margin-right: 20px;
            width: 10px;
          }
          .nav-right {
            display: inline-block;
            vertical-align: middle;
            width: 380px;
            p {
              font-size: 16px;
              margin: 10px 0;
            }
          }
        }
      }
    }
    .tab-content {
      animation-duration: 0.5s;
      box-sizing: border-box;
      color: #fff;
      display: none;
      left: 0;
      padding: 15px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}
.five-demo {
  margin: auto;
  padding: 80px 0;
  text-align: center;
  .five-title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .five-list {
    left: 60px;
    margin: auto;
    overflow: hidden;
    position: relative;
    right: 0;
    width: 950px;
    .five-img {
      left: 330px;
      position: absolute;
      top: 105px;
      img {
        width: 180px;
      }
      .img-content {
        bottom: 0;
        color: #fff;
        font-size: 16px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 50px;
      }
    }
    .img {
      background-image: url(https://site-manager.oss-cn-shanghai.aliyuncs.com/images/2020/02/15/15817564986792730.png);
      background-size: cover;
    }
    .list {
      float: left;
      height: 100px;
      margin: 40px 30px;
      padding-top: 25px;
      vertical-align: middle;
      width: 380px;
      p {
        font-size: 16px;
        margin: auto;
        text-align: left;
        width: 300px;
      }
    }
  }
}
.seven-demo {
  overflow: hidden;
  padding: 80px 0;
  p {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }
  .seven-img {
    float: left;
    margin-left: 20px;
    width: 500px;
  }
  .seven-list {
    display: inline-block;
    position: relative;
    width: 500px;
    .seven-aboust {
      position: absolute;
      right: -50px;
      top: 50px;
      width: 380px;
      p {
        color: #333;
        font-size: 32px;
        font-weight: 700;
        text-align: left;
      }
      .seven-text {
        color: #9c9c9c;
        font-size: 14px;
        margin: 15px 0;
      }
    }
  }
}
.six-demo {
  overflow: hidden;
  padding: 80px 0;
  p {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }
  .six-demo-fex {
    align-items: flex-start;
    display: flex;
  }
  .six-list {
    display: inline-block;
    padding: 0 80px;
    position: relative;
    width: 500px;
    p {
      color: #333;
      font-size: 32px;
      font-weight: 700;
      text-align: left;
    }
    .six-text {
      color: #9c9c9c;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 20px;
      margin: 15px 0;
    }
    .six-aboust {
      left: 20px;
      position: absolute;
      top: 50px;
      width: 400px;
    }
  }
  .six-img {
    margin-right: 40px;
    width: 500px;
  }
}
.twenty-demo {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 80px 0;
  .left-text {
    display: inline-block;
    font-size: 28px;
    font-weight: 700;
    margin-left: 40px;
    margin-right: 220px;
    vertical-align: top;
    width: 310px;
  }
  .right-list {
    display: inline-block;
    margin-top: 30px;
    text-align: center;
    width: 430px;
    .list-icon {
      display: inline-block;
      margin-bottom: 30px;
      width: 135px;
      img {
        margin: auto auto 10px;
        width: 60px;
      }
      .icon-text {
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        text-align: center;
      }
    }
  }
}
.second-demo {
  padding: 80px 0;
  text-align: center;
  p {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 80px;
  }
  .icon-card {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 9px 12px 0 rgba(212, 221, 226, 0.5);
    display: inline-block;
    margin-bottom: 40px;
    margin-right: 40px;
    top: 60px;
    width: 460px;
    .icon-bg {
      background: #3878ff;
      border-radius: 4px 4px 0 0;
      height: 75px;
      position: relative;
      .img-bg {
        background: #fff;
        border-radius: 50%;
        height: 50px;
        left: 40px;
        line-height: 50px;
        overflow: hidden;
        position: absolute;
        text-align: center;
        top: 14px;
        width: 50px;
        img {
          bottom: 0;
          left: 0;
          margin: auto;
          position: absolute;
          right: 0;
          top: 0;
          width: 35px;
        }
      }
      .img-text {
        color: #fff;
        font-size: 22px;
        left: 110px;
        position: absolute;
        top: 25px;
      }
    }
    .icon-text {
      color: #767676;
      font-size: 16px;
      height: 60px;
      margin: 25px;
      text-align: left;
    }
  }
}
.new-first {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 120px 0;
  .comp-atible {
    margin: auto;
    overflow: hidden;
    width: 1080px;
  }
  p {
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
  }
  .atrible-text {
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
}

.third-demo {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 88px 0;
  .comp-atible {
    margin: auto;
    overflow: hidden;
    width: 1080px;
  }
  .love-row {
    float: left;
    margin: 30px 0;
    text-align: center;
    width: 30%;
    .icon-title {
      font-size: 24px;
      margin-top: 30px;
    }
    .icon-text {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
.ten-demo {
  padding: 80px 0;
  text-align: center;
  p {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 80px;
  }
  .ten-img {
    float: left;
    margin-left: 40px;
    width: 420px;
  }
  .ten-list {
    display: inline-block;
    padding: 0 80px;
    position: relative;
    text-align: left;
    width: 320px;
    .ten-aboust {
      left: 80px;
      position: absolute;
      top: -4px;
    }
    p {
      color: #333;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
      margin-top: 0;
    }
    .ten-text {
      color: #8c8888;
      font-size: 14px;
      margin: 35px 0;
      width: 400px;
    }
  }
  .ten-li {
    list-style: none;
    margin-top: 20px;
    padding: 0;
    li {
      color: #565555;
      font-size: 16px;
      margin-bottom: 20px;
      img {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        width: 25px;
      }
    }
  }
}
.seventeen-demo {
  padding: 80px 0 50px;
  text-align: center;
  p {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  .love-row {
    display: inline-block;
    margin: 20px 40px;
    text-align: left;
    width: 450px;
    img {
      display: inline-block;
      margin: auto;
      width: 65px;
    }
    .icon-text {
      display: inline-block;
      font-size: 14px;
      margin-left: 15px;
      text-align: left;
      vertical-align: top;
      width: 320px;
      p {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 0;
      }
    }
  }
}
.sixteen-demo {
  overflow: hidden;
  padding: 80px 0;
  text-align: center;
  p {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .sixteen-text {
    font-size: 12px;
    margin: auto;
    text-align: center;
  }
  .sixteen-list {
    float: left;
    margin-right: 40px;
    width: 290px;
    .list-header {
      border-bottom: 1px solid #efecec;
      font-size: 16px;
      margin-bottom: 25px;
      overflow: hidden;
      padding: 20px 0;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 290px;
      .list-num {
        font-size: 18px;
        font-weight: 600;
      }
    }
    img {
      width: 290px;
    }
    .list-footer {
      font-size: 12px;
      letter-spacing: 2px;
      margin-top: 20px;
      text-align: left;
      width: 290px;
    }
  }
}
.first-demo {
  padding: 80px 0;
  text-align: center;
  p {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .love-row {
    display: inline-block;
    margin: 40px 0;
    text-align: center;
    width: 30%;
    img {
      margin: auto;
      width: 60px;
    }
    .icon-text {
      font-size: 16px;
      margin-top: 20px;
    }
  }
}
.comp-atible {
  margin: auto;
  overflow: hidden;
  width: 1080px;
}
.btn-exten {
  border: none;
  border-radius: 28px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  outline: none;
  padding: 10px 35px;
}
.fillet {
  border-radius: 28px !important;
}
</style>
